<template>
  <subview class="newcompetition-appearence-judgements">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.newcompetition.appearance.back') }}
          </div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <h4> Gender </h4>
      <div 
        v-for="(gender, key, ind) in genders" 
        :key="ind" 
        class="gender-component" 
        :class="{ selected: selected_options.includes(key) }" 
        @click="select(key)"
      >
        <span class="name">
          {{ gender }}
        </span>
      </div>
    </view-content>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Gender',
  mixins: [
    newcompetition
  ],
  components: {
    Icon,
  },
  data() {
    return {
      genders: {
        mixed: "Mixed",
        man: "Man",
        woman: "Woman",
      },
       selected_options: [],
    }
  },
  created() {
    this.select(this.selectedGender)
  },
  methods: {
    select(gender) {
      this.setGender(gender);
      this.$store.dispatch('getGenderOpponets', gender)
      this.selected_options = [];
      if (this.selected_options.includes(gender)) {
          this.selected_options = this.selected_options.filter(
            (item) => item !== gender
          );
        } else {
          this.selected_options.push(gender);
        }
    },
  }
}
</script>
